/*----------------------------------------------------------------------------*\
  #ONESITE SHARED PACK
\*----------------------------------------------------------------------------*/

/* eslint no-unused-vars: "off" */

/**
 * POLYFILLS
 */
import 'polyfills/main';

/**
 * ASSETS
 * Import assets for use in our app.
 */

// Styles
import 'shared.scss';

// Images
const images = require.context('images', true);

import CheckInOut from 'modules/shared/check-in-out';
import VisitorCheckIn from 'modules/shared/visitor-check-in';

// Remove old service worker as it's not needed
navigator.serviceWorker.getRegistrations().then(function(registrations) {
  for(let registration of registrations) { registration.unregister(); }
});

/**
 * MODULES
 * Import modules for use in our app.
 */

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import Interactions from 'modules/shared/interactions';
import ToggleClass from 'modules/shared/toggle-class';
import Notice from 'modules/shared/notice';
import AutocompleteHiddenValue from 'modules/shared/autocomplete-hidden-value';

import { methodHandler, getModule } from 'util/methods';

// Create a modules object holding all of our module instances
const modules = {
  interactions: new Interactions(),
  toggleClass: new ToggleClass(),
  notice: new Notice(),
  autocompleteHiddenValue: new AutocompleteHiddenValue(),
  checkInOut: new CheckInOut(),
  VisitorCheckIn: new VisitorCheckIn(),
};

/**
 * METHODS
 * We create events to hook into these methods so we can trigger
 * them from individual modules.
 */

// Init method
const init = event => {
  const module = getModule(event);
  methodHandler('init', module, modules);
};

// Init Rails & Turbolinks
const initRails = () => {
  if (!window._rails_loaded) Rails.start();
  if (!window.Turbolinks) Turbolinks.start();

  Turbolinks.clearCache();
};

// Reinit method
const reinit = event => {
  const module = getModule(event);
  methodHandler('reinit', module, modules);
};

// Destroy method
const destroy = event => {
  const module = getModule(event);
  methodHandler('destroy', module, modules);
};

// Trigger methods from events
document.addEventListener('shared:init', init);
document.addEventListener('shared:reinit', reinit);
document.addEventListener('shared:destroy', destroy);
document.addEventListener('turbolinks:load', reinit);

// Run our init method on load
document.addEventListener('DOMContentLoaded', initRails);
